import { useAppSelector } from 'state/redux-hooks/reduxHooks';
import classes from './FilterSection.module.scss';
import { useCallback } from 'react';
import { FilterCategory } from 'config/constants';
import { selectFilters } from '../redux/filterSlice';
import { formatSingleOrganizationLocationFilter } from 'utils/utilFunctions';
import { OrganizationLocationFilter } from 'modules/shared/model/organizationLocationFilter';

type Props = {
  title: string;
  filters?: string[];
  locations?: OrganizationLocationFilter[];
  filterCategory: FilterCategory;
  handleOnClick: (
    filterValue: string,
    index: number,
    location?: OrganizationLocationFilter,
  ) => void;
};

export const FilterSection = ({
  title,
  filters,
  filterCategory,
  locations,
  handleOnClick,
}: Props) => {
  const { appliedFilters } = useAppSelector(selectFilters);

  const isSelected = useCallback(
    (item: string): boolean => {
      if (filterCategory === FilterCategory.SORT)
        return appliedFilters.sortBy.id === filters?.indexOf(item);

      return !!appliedFilters[filterCategory].find((filter) => filter === item);
    },
    [appliedFilters, filterCategory, filters],
  );

  const isLocationSelected = useCallback(
    (item: OrganizationLocationFilter): boolean => {
      return !!appliedFilters[FilterCategory.LOCATION].find(
        (filter) => filter.organizationLocationId === item.organizationLocationId,
      );
    },
    [appliedFilters],
  );

  const renderFilterItem = useCallback(
    (item: string, index: number) => {
      if (
        filterCategory === FilterCategory.SORT &&
        appliedFilters.sortBy.filterValue &&
        appliedFilters.sortBy.id === index
      )
        return appliedFilters.sortBy.filterValue;

      return item;
    },
    [appliedFilters.sortBy.filterValue, appliedFilters.sortBy.id, filterCategory],
  );

  return (
    <div className={classes['c-filter-section']}>
      <div className={classes['c-filter-section__title']}>{title}</div>
      <div className={classes['c-filter-section__filters-container']}>
        {/* We get one null value from BE for departments, that's why we need to filter it out */}
        {filterCategory === FilterCategory.LOCATION
          ? locations?.map((location, index) => (
              <button
                key={location.organizationLocationId}
                className={`${classes['c-filter-section__item']} 
                ${
                  isLocationSelected(location)
                    ? classes['c-filter-section__item--selected']
                    : classes['c-filter-section__item--default']
                }
              `}
                onClick={() =>
                  handleOnClick(formatSingleOrganizationLocationFilter(location), index, location)
                }
              >
                {formatSingleOrganizationLocationFilter(location)}
              </button>
            ))
          : filters?.filter(Boolean)?.map((item, index) => (
              <button
                key={index}
                className={`${classes['c-filter-section__item']} ${
                  isSelected(item)
                    ? classes['c-filter-section__item--selected']
                    : classes['c-filter-section__item--default']
                }
            `}
                onClick={() => handleOnClick(item, index)}
              >
                {!!renderFilterItem && renderFilterItem(item, index)}
              </button>
            ))}
      </div>
    </div>
  );
};
