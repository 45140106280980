import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { FilterCategory, RequestState } from 'config/constants';
import { State } from 'state/store';
import { type FetchedFilters } from '../Filter';
import { getAllFilters } from './filterActions';
import { OrganizationLocationFilter } from 'modules/shared/model/organizationLocationFilter';

const filterValueMap: Record<number, Record<number, string>> = {
  0: {
    1: '',
    2: 'Name A -> Z',
    3: 'Name Z -> A',
  },
  1: {
    1: '',
    2: 'Surname A -> Z',
    3: 'Surname Z -> A',
  },
  2: {
    1: '',
    2: 'Oldest -> Newest',
    3: 'Newest -> Oldest',
  },
};

type ActionType = 'toggle' | 'add';

export type SortByFilter = {
  id: number;
  filterValue: string;
  filterState: 1 | 2 | 3;
};

export type AppliedFilters = {
  departmentNames: string[];
  locations: OrganizationLocationFilter[];
  projectNames: string[];
  workflowNames: string[];
  sortBy: SortByFilter;
};

export type FilterState = {
  appliedFilters: AppliedFilters;
  fetchedFilters: FetchedFilters;
  initialFetch: boolean;
  loading: RequestState;
};

const initialState: FilterState = {
  appliedFilters: {
    locations: [],
    departmentNames: [],
    projectNames: [],
    workflowNames: [],
    sortBy: { id: -1, filterValue: '', filterState: 1 },
  },
  fetchedFilters: { locations: [], departmentNames: [], projectNames: [] },
  initialFetch: false,
  loading: RequestState.IDLE,
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    initializeFilters: () => initialState,
    initializeAppliedFilters: (state) => {
      state.appliedFilters = initialState.appliedFilters;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
    },
    clearAppliedFilters: (state) => {
      state.appliedFilters = initialState.appliedFilters;
    },
    setFetchedFilters: (state, action) => {
      state.fetchedFilters = action.payload;
    },
    toggleFilter: (
      state,
      action: PayloadAction<{
        filterCategory: string;
        filterValue: string;
        actionType?: ActionType;
        location?: OrganizationLocationFilter;
      }>,
    ) => {
      const { filterCategory, filterValue, actionType, location } = action.payload;

      if (filterCategory === FilterCategory.SORT) {
        state.appliedFilters.sortBy.filterValue = filterValue;
        return;
      }

      if (filterCategory === FilterCategory.LOCATION && location) {
        const locationIndex = state.appliedFilters.locations.findIndex(
          (existingLocation) =>
            existingLocation.organizationLocationId === location.organizationLocationId,
        );

        if (locationIndex !== -1) {
          state.appliedFilters.locations.splice(locationIndex, 1);
        } else {
          state.appliedFilters.locations.push(location);
        }
        return;
      }

      const categoryFilters = state.appliedFilters[
        filterCategory as keyof Omit<AppliedFilters, 'sortBy'>
      ] as unknown as string[];

      if (!categoryFilters.includes(filterValue)) {
        categoryFilters.push(filterValue);
      } else if (actionType !== 'add') {
        categoryFilters.splice(
          categoryFilters.findIndex((currentFilter) => currentFilter === filterValue),
          1,
        );
      }
    },
    removeFilter: (state, action) => {
      const { payload } = action;

      const lastCommaIndex = payload.lastIndexOf(',');
      const address = payload.slice(0, lastCommaIndex).trim();
      const city = payload.slice(lastCommaIndex + 1).trim();

      state.appliedFilters = {
        ...state.appliedFilters,
        departmentNames: state.appliedFilters.departmentNames.filter((item) => item !== payload),
        locations: state.appliedFilters.locations.filter(
          (location) => !(location.address === address && location.city === city),
        ),
        projectNames: state.appliedFilters.projectNames.filter((item) => item !== payload),
        sortBy:
          state.appliedFilters.sortBy.filterValue === payload
            ? {
                ...state.appliedFilters.sortBy,
                filterValue: '',
                id: -1,
                filterState: 1,
              }
            : state.appliedFilters.sortBy,
        workflowNames: state.appliedFilters.workflowNames.filter((item) => item !== payload),
      };
    },
    markFilterById: (
      state,
      action: PayloadAction<{ id: number; actionType?: ActionType; asc?: boolean }>,
    ) => {
      const { id, actionType, asc } = action.payload;

      if (actionType === 'add') {
        state.appliedFilters.sortBy.id = id;
        if (!asc) {
          state.appliedFilters.sortBy.filterValue = filterValueMap[id][3];
          state.appliedFilters.sortBy.filterState = 3;
        } else {
          state.appliedFilters.sortBy.filterValue = filterValueMap[id][2];
          state.appliedFilters.sortBy.filterState = 2;
        }
        return;
      }

      if (state.appliedFilters.sortBy.id !== id) {
        state.appliedFilters.sortBy.filterState = 2;
      }

      if (state.appliedFilters.sortBy.id === id) {
        state.appliedFilters.sortBy.filterState = ((state.appliedFilters.sortBy.filterState % 3) +
          1) as 1 | 2 | 3;
      }

      if (id in filterValueMap) {
        state.appliedFilters.sortBy.filterValue =
          filterValueMap[id][state.appliedFilters.sortBy.filterState];

        if (state.appliedFilters.sortBy.filterState === 1) {
          state.appliedFilters.sortBy.id = -1;
        } else {
          state.appliedFilters.sortBy.id = id;
        }
      } else {
        state.appliedFilters.sortBy.filterValue = '';
        state.appliedFilters.sortBy.id = -1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFilters.pending, (state) => {
        state.loading = RequestState.PENDING;
      })
      .addCase(getAllFilters.fulfilled, (state, action) => {
        state.loading = RequestState.FULFILLED;
        state.fetchedFilters = action.payload;
        state.initialFetch = true;
      })
      .addCase(getAllFilters.rejected, (state) => {
        state.loading = RequestState.REJECTED;
      });
  },
});

export const selectFilters = (state: State) => state.filters;

export const {
  initializeFilters,
  initializeAppliedFilters,
  clearAppliedFilters,
  toggleFilter,
  removeFilter,
  setFetchedFilters,
  setInitialFetch,
  markFilterById,
} = slice.actions;

export default slice.reducer;
