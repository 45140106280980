import { type ChangeEvent, useEffect, useState, useRef, useCallback } from 'react';
import Section, { SectionType } from '../../Section/Section';
import classes from './ManageCompany.module.scss';
import { ReactComponent as IDIcon } from 'assets/IDIcon.svg';
import { ReactComponent as ImageIcon } from 'assets/ImageIcon.svg';
import { ReactComponent as UploadPhoto } from 'assets/PhotoCamers.svg';
import { ReactComponent as Cancel } from 'assets/Cancel.svg';
import { ReactComponent as Save } from 'assets/Save.svg';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { selectSettings, setCompany, setLogo } from 'modules/settings/redux/settingsSlice';
import { updateCompany, uploadCompanyLogo } from 'modules/settings/api';
import toast from 'utils/notifications/CustomToast';
import { Button, PopUpModal } from 'components/core';
import { SectionInfoEdit } from 'modules/shared/components';
import { Nullable } from 'modules/shared/model';
import logoPlaceholder from '../../../../../assets/Building.svg';
import CropImage from 'components/core/CropImage/CropImage';
import { mapErrorToErrorData } from 'utils';

const ManageCompany = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoChanged, setIsLogoChanged] = useState(false);
  const [sectionType, setSectionType] = useState<SectionType>('overview');
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [previewLogoPath, setPreviewLogoPath] = useState<Nullable<string>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const hidden = useRef<HTMLInputElement | null>(null);
  const [croppedPicture, setCroppedPicture] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const { company } = useAppSelector(selectSettings);
  const dispatch = useAppDispatch();

  const hanleModalOpen = () => setIsModalOpen(true);

  const [newNameInputValue, setNewNameInputValue] = useState<string>(company?.name ?? '');

  const handleSave = async () => {
    setIsLoading(true);

    try {
      const { data } = await updateCompany(newNameInputValue);
      dispatch(setCompany({ ...data, name: data.name }));
      if (isLogoChanged && logoFile) {
        const { data } = await uploadCompanyLogo(croppedPicture!);
        dispatch(setLogo({ logoPath: data, logoDisplayName: logoFile.name }));
      }

      toast.success('You have successfully updated company manage info.');
      setLogoFile(null);
    } catch (error) {
      const errorData = mapErrorToErrorData(error);
      toast.error(errorData?.message || 'Something went wrong while saving. Try again.');
    } finally {
      setIsLoading(false);
      setSectionType('overview');
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    setLogoFile(event.target.files[0]);
  };

  const handleUploadLogo = async () => {
    if (!logoFile) return toast.warning('Please upload the image first.');

    try {
      setIsLogoChanged(true);
      setIsModalOpen(false);
    } catch {
      toast.error('Something went wrong while uploading the file. Try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPreviewLogoPath(null);
    setLogoFile(null);
  };

  const handleCancel = () => {
    setSectionType('overview');
    setNewNameInputValue(company?.name ?? '');
  };

  const handleOnClickActivateInput = () => {
    if (hidden?.current) {
      hidden.current.click();
    }
  };

  const handleSetEditType = useCallback(() => {
    setSectionType('edit');
  }, []);

  const displayImage = () => {
    if (previewLogoPath) return previewLogoPath;
    if (company?.logoPath) return company?.logoPath;

    return logoPlaceholder;
  };

  useEffect(() => {
    if (!logoFile) {
      setPreviewLogoPath(null);
      return;
    }

    setPreviewLogoPath(URL.createObjectURL(logoFile));
  }, [logoFile]);

  return (
    <Section
      type={sectionType}
      title="Manage company"
      iconType="edit"
      disabled={!isLogoChanged && newNameInputValue === company?.name}
      onClick={handleSetEditType}
      onSave={handleSave}
      onCancel={handleCancel}
      isLoading={isLoading}
    >
      <div className={classes['c-manage-access']}>
        <div className={classes['c-manage-access__column']}>
          <SectionInfoEdit
            name="name"
            data={newNameInputValue}
            icon={<IDIcon />}
            setValue={setNewNameInputValue}
            readOnly={sectionType === 'overview'}
          />
        </div>
        <div className={classes['c-manage-access__item']}>
          <span className={classes['c-manage-access__item-label']}>
            <ImageIcon /> Company logo
          </span>
          {!!company?.logoPath && (
            <img
              className={classes['c-manage-access__item-logo']}
              src={previewLogoPath ?? company.logoPath}
              alt="Company logo"
            />
          )}
          {sectionType === 'edit' && (
            <>
              <Button onClick={hanleModalOpen} type="secondary">
                Change image
              </Button>
              <PopUpModal
                opened={isModalOpen}
                title="Change company logo"
                width={35}
                closeModal={handleCloseModal}
              >
                <div className={classes['c-manage-access__modal-wrap']}>
                  <div className={classes['c-manage-access__chose-file']}>
                    <div className={classes['c-manage-access__modal-logo-wrap']}>
                      {logoFile ? (
                        <CropImage
                          imageUrl={displayImage()}
                          setCroppedPicture={setCroppedPicture}
                          setIsProcessing={setIsProcessing}
                        />
                      ) : (
                        <img
                          className={classes['c-manage-access__modal-logo']}
                          src={displayImage()}
                          alt="Company logo"
                        />
                      )}
                    </div>
                  </div>
                  <div className={classes['c-manage-access__input-file-container']}>
                    <input
                      ref={hidden}
                      id="logoUpload"
                      className={classes['c-manage-access__input-file']}
                      type="file"
                      accept="image/*"
                      multiple={false}
                      onChange={handleFileChange}
                    />
                  </div>

                  <div className={classes['c-manage-access__confirmation-buttons-wrap']}>
                    <Button
                      type="tertiary"
                      size="large"
                      onClick={handleCloseModal}
                      leftIcon={<Cancel />}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      isLoading={isLoading}
                      onClick={handleUploadLogo}
                      disabled={!logoFile || isProcessing}
                      leftIcon={<Save />}
                    >
                      {isProcessing ? 'Precessing...' : 'Save'}
                    </Button>
                    <Button
                      type="secondary"
                      size="large"
                      isLoading={isLoading}
                      leftIcon={<UploadPhoto />}
                      onClick={handleOnClickActivateInput}
                    >
                      Upload
                    </Button>
                  </div>
                </div>
              </PopUpModal>
            </>
          )}
        </div>
      </div>
    </Section>
  );
};

export default ManageCompany;
