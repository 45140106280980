import classes from './CropImage.module.scss';
import ReactCrop, { centerCrop, convertToPixelCrop, Crop, makeAspectCrop } from 'react-image-crop';

import { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import setCanvasPreview from './utils/setCanvasPreview';
import { toast } from 'react-toastify';

type Props = {
  imageUrl: string;
  setCroppedPicture: React.Dispatch<React.SetStateAction<File | null>>;
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>;
};

const CropImage = ({ imageUrl, setCroppedPicture, setIsProcessing }: Props) => {
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const cropTimeoutRef = useRef<number | null>(null);

  const onImageLoad = (event: SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = event.currentTarget;
    const cropWidthInPercent = (150 / width) * 100;

    const crop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent,
      },
      1,
      width,
      height,
    );

    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleCropChange = (percentCrop: Crop) => {
    setCrop(percentCrop);
    setIsProcessing(true);
  };

  const handleOnComplete = useCallback(() => {
    if (cropTimeoutRef.current) {
      clearTimeout(cropTimeoutRef.current);
    }
    setIsProcessing(true);

    if (!crop) {
      toast.error('Please crop image first.');
      return;
    }

    cropTimeoutRef.current = window.setTimeout(() => {
      if (imgRef.current && previewCanvasRef.current && crop.width && crop.height) {
        const pixelCrop = convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height);
        setCanvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          pixelCrop,
          setCroppedPicture,
          setIsProcessing,
        );
      }
    }, 200);
  }, [crop, setCroppedPicture, setIsProcessing]);

  useEffect(() => {
    return () => {
      if (cropTimeoutRef.current) {
        clearTimeout(cropTimeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <ReactCrop
        crop={crop}
        onComplete={handleOnComplete}
        keepSelection
        aspect={1}
        minWidth={150}
        onChange={handleCropChange}
      >
        <img
          ref={imgRef}
          className={classes['c-crop-image__preview']}
          src={imageUrl}
          alt="profile-picture"
          onLoad={onImageLoad}
        />
      </ReactCrop>
      {crop && <canvas ref={previewCanvasRef} className={classes['c-crop-image__canvas']} />}
    </>
  );
};

export default CropImage;
