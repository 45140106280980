import { type ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import ProfileNav from './ProfileNav';
import classes from '../styles/Profile.module.scss';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { getUserByOrganization } from '../../shared/redux/userActions';
import { Role } from 'config/constants';
import { uploadProfilePicture } from '../api/profile.api';
import { selectUser, setProfilePhoto } from '../redux/userSlice';
import { PopUpModal, Status, toast, Button } from 'components/core';
import { ReactComponent as UploadPhoto } from 'assets/PhotoCamers.svg';
import { ReactComponent as Cancel } from 'assets/Cancel.svg';
import { ReactComponent as Save } from 'assets/Save.svg';
import {
  selectCurrentUser,
  updateProfilePhoto,
} from 'components/layout/Toolbar/redux/toolbarSlice';
import avatarPlaceholder from '../../../assets/AvatarPlaceholder.svg';
import CropImage from 'components/core/CropImage/CropImage';
import { mapErrorToErrorData } from 'utils';

const Profile = () => {
  const [profilePictureFile, setProfilePictureFile] = useState<File | null>(null);
  const [previewPicturePath, setPreviewPicturePath] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useAppSelector(selectUser);
  const hidden = useRef<HTMLInputElement | null>(null);
  const [croppedPicture, setCroppedPicture] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { id } = useParams();
  const { id: profileUserId } = useAppSelector(selectUser);
  const loggedInUser = useAppSelector(selectCurrentUser);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setProfilePictureFile(null);
  }, []);

  const handleOpenModal = useCallback(() => setIsModalOpen(true), []);

  const handleFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;

    setProfilePictureFile(event.target.files[0]);
    setIsModalOpen(true);
  }, []);

  const handleUploadLogo = useCallback(async () => {
    if (!croppedPicture || !id) {
      toast('error', 'Please upload picture first.');
      return;
    }
    setIsUploading(true);
    try {
      const response = await uploadProfilePicture(id, croppedPicture);
      dispatch(setProfilePhoto(response.data));

      if (id === String(loggedInUser?.id)) dispatch(updateProfilePhoto(response.data));

      setIsModalOpen(false);
      setProfilePictureFile(null);
      toast('success', 'You successfully uploaded profile picture.');
    } catch (error) {
      const errorData = mapErrorToErrorData(error);
      toast(
        'error',
        errorData?.message || 'Something went wrong while uploading the file. Try again.',
      );
    } finally {
      setIsUploading(false);
    }
  }, [croppedPicture, id, loggedInUser?.id, dispatch]);

  const handleOpenPreviewModal = () => {
    if (hidden?.current) {
      hidden.current.click();
    }
  };

  const displayImage = () => {
    if (previewPicturePath) return previewPicturePath;
    if (user.profilePhotoPath) return user.profilePhotoPath;

    return avatarPlaceholder;
  };

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);

    try {
      dispatch(getUserByOrganization(id));
    } catch {
      toast('error', 'Something went wrong while fetching data. Try again.');
    } finally {
      setIsLoading(false);
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (!id) return;

    if (loggedInUser?.role === Role.USER && loggedInUser.id !== +id) {
      navigate(`/user/${loggedInUser.id}/about`);
      return;
    }
  }, [id, profileUserId, loggedInUser, dispatch, navigate]);

  useEffect(() => {
    if (!profilePictureFile) {
      setPreviewPicturePath(null);
      return;
    }

    setPreviewPicturePath(URL.createObjectURL(profilePictureFile));
  }, [profilePictureFile]);

  return (
    <Status isLoading={isLoading}>
      <div className={classes['c-profile']}>
        <ProfileNav userId={id} onAvatarUpload={handleOpenModal} />
        <PopUpModal
          title="Change profile picture"
          width={30}
          opened={isModalOpen}
          isConfirmationModal
          closeModal={handleCloseModal}
        >
          <div className={classes['c-profile__modal-content']}>
            <div className={classes['c-profile__chose-file']}>
              <div className={classes['c-profile__modal-content-picture-container']}>
                {profilePictureFile ? (
                  <CropImage
                    setIsProcessing={setIsProcessing}
                    imageUrl={displayImage()}
                    setCroppedPicture={setCroppedPicture}
                  />
                ) : (
                  <img
                    className={classes['c-profile__modal-content-picture']}
                    src={displayImage()}
                    alt="profile-picture"
                  />
                )}
              </div>
            </div>
            <div className={classes['c-profile__input']}>
              <input
                ref={hidden}
                type="file"
                id="profilePhotoUpload"
                accept="image/*"
                multiple={false}
                onChange={handleFileChange}
              />
            </div>
            <div className={classes['c-profile__confirmation-buttons-wrap']}>
              <Button type="tertiary" size="large" onClick={handleCloseModal} leftIcon={<Cancel />}>
                Cancel
              </Button>
              <Button
                size="large"
                isLoading={isUploading}
                onClick={handleUploadLogo}
                disabled={!profilePictureFile || isProcessing}
                leftIcon={<Save />}
              >
                {isProcessing ? 'Precessing...' : 'Save'}
              </Button>
              <Button
                type="secondary"
                size="large"
                isLoading={isLoading}
                onClick={handleOpenPreviewModal}
                leftIcon={<UploadPhoto />}
              >
                Upload
              </Button>
            </div>
          </div>
        </PopUpModal>
        <div className={classes['c-profile__content']}>
          <Outlet />
        </div>
      </div>
    </Status>
  );
};

export default Profile;
