import Modal from 'react-modal';
import { ReactComponent as CloseSvg } from 'assets/CloseIcon.svg';
import classes from './PopUpModal.module.scss';
import { useOnClickOutside } from 'hooks';
import { IconButton } from '../../IconButton';

type Props = {
  title?: string;
  opened: boolean;
  width: number;
  children: React.ReactNode;
  top?: string;
  hidden?: boolean;
  isConfirmationModal?: boolean;
  closeModal?: () => void;
  closeModalOnClickOutside?: () => void;
  shouldClose?: boolean;
};

export const PopUpModal = ({
  title,
  opened,
  children,
  width,
  hidden,
  top,
  isConfirmationModal,
  closeModal,
  closeModalOnClickOutside,
  shouldClose = true,
}: Props) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(119,119,119,0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: top ? '' : 'center',
      zIndex: 999,
      visibility: hidden ? ('hidden' as const) : ('visible' as const),
      overflow: 'auto',
    },
    content: {
      width: `${width}%`,
      inset: top ? `${top} auto auto` : 'auto',
      border: 'none',
      borderRadius: '30px',
      zIndex: 999,
      overflow: 'visible',
      padding: 0,
    },
  };

  const modalRef = useOnClickOutside(() => {
    if (!shouldClose) return;

    if (!closeModalOnClickOutside && closeModal) closeModal();
    else if (closeModalOnClickOutside) closeModalOnClickOutside();
  });

  return (
    <Modal
      isOpen={opened}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div
        className={`${classes['c-pop-up-modal']} ${
          isConfirmationModal ? classes['c-pop-up-modal--confirmation-modal'] : ''
        }`}
        ref={modalRef}
      >
        <div className={classes['c-pop-up-modal__header']}>
          {isConfirmationModal && !title ? (
            <span className={classes['c-pop-up-modal__title']}>Are you sure</span>
          ) : (
            <div className={classes['c-pop-up-modal__title']}>{title}</div>
          )}
          <IconButton
            icon={<CloseSvg />}
            aria-label="Close"
            onClick={closeModal}
            iconButtonType="close"
          />
        </div>
        <div className={classes['c-pop-up-modal__content']}>{children}</div>
      </div>
    </Modal>
  );
};
