import React from 'react';
import { PixelCrop } from 'react-image-crop';
import { toast } from 'react-toastify';

const setCanvasPreview = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  setCroppedPicture: React.Dispatch<React.SetStateAction<File | null>>,
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
): void => {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    toast.error('No 2D context.');
    return;
  }

  const pixelRatio = Math.min(window.devicePixelRatio, 1.5);
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';
  ctx.save();

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  ctx.translate(-cropX, -cropY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  canvas.toBlob((blob) => {
    if (blob) {
      const croppedImageFile = new File([blob], 'new-picture', {
        type: 'image/png',
      });
      setCroppedPicture(croppedImageFile);
    }
    setIsProcessing(false);
  });
  ctx.restore();
};

export default setCanvasPreview;
