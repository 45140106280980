import classes from './AppliedFilters.module.scss';
import { ReactComponent as ClearFilter } from 'assets/ClearFilter.svg';
import { useAppDispatch, useAppSelector } from 'state/redux-hooks/reduxHooks';
import { removeFilter, clearAppliedFilters } from './../redux/filterSlice';
import { Button, FilterButton } from 'components/core';
import { selectToolbar } from 'components/layout/Toolbar/redux/toolbarSlice';

export const AppliedFilters = () => {
  const { appliedFilters } = useAppSelector((state) => state.filters);
  const { filterClicked } = useAppSelector(selectToolbar);

  const dispatch = useAppDispatch();

  const appliedFiltersList = Object.entries(appliedFilters).reduce<string[]>(
    (filteredList, [key, value]) => {
      if (Array.isArray(value)) {
        if (
          key === 'locations' &&
          value.every((item) => typeof item === 'object' && 'address' in item && 'city' in item)
        ) {
          return filteredList.concat(
            value.map((location) => `${location.address}, ${location.city}`),
          );
        }
        return filteredList.concat(value as string[]);
      }
      if (key === 'sortBy' && value.filterValue !== '') {
        return filteredList.concat(value.filterValue);
      }
      return filteredList;
    },
    [],
  );

  return (
    <div
      className={`${classes['c-applied-filters']} ${
        filterClicked ? classes['c-applied-filters--opened-filters'] : ''
      }`}
    >
      <div className={classes['c-applied-filters__list']}>
        {appliedFiltersList.map((filter, index) => (
          <FilterButton
            key={index}
            label={filter}
            type="selective"
            onClick={() => dispatch(removeFilter(filter))}
          />
        ))}
      </div>
      {!!appliedFiltersList.length && (
        <div className={classes['c-applied-filters__clear-filters']}>
          <Button
            variant="text"
            leftIcon={<ClearFilter className={classes['c-applied-filters__clear-filters--icon']} />}
            onClick={() => dispatch(clearAppliedFilters())}
          >
            Clear filters
          </Button>
        </div>
      )}
    </div>
  );
};
