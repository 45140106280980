import { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Spinner, toast } from 'components/core';
import { loginWithOAuth } from '../../../api/auth.api';
import { signIn } from '../../../utils';
import { mapErrorToErrorData } from 'utils';
import classes from './OAuthRedirect.module.scss';
import { OAuthProvider } from '../../../config';
import { useOAuth } from '../../../hooks';

type Props = {
  provider: OAuthProvider;
};

const OAuthRedirect = ({ provider }: Props) => {
  const { loginInProgress, idToken, error } = useOAuth();
  const [loginComplete, setLoginComplete] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const handleLogin = useCallback(async () => {
    if (!idToken) return;

    const defaultErrorMessage = 'Something went wrong. Please try again.';

    if (error) {
      toast('error', defaultErrorMessage);
      return;
    }

    try {
      const { data } = await loginWithOAuth(idToken, provider);
      signIn(data);
      setLoginComplete(true);
    } catch (error) {
      const errorData = mapErrorToErrorData(error);

      if (errorData?.status === 400) {
        toast('error', errorData.message);
        return;
      }

      toast('error', defaultErrorMessage);
    }
  }, [idToken, error, provider]);

  useEffect(() => {
    if (!loginInProgress && !idToken && error) {
      setCanceled(true);
      return;
    }

    if (idToken && !loginComplete) {
      handleLogin();
    }
  }, [idToken, loginComplete, error, handleLogin, loginInProgress]);

  if (canceled) {
    return <Navigate to="/login" replace />;
  }

  if (loginInProgress || !loginComplete) {
    return (
      <div className={classes['c-oauth-redirect']}>
        <Spinner />
      </div>
    );
  }

  return <Navigate to="/people" replace />;
};

export default OAuthRedirect;
